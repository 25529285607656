@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/opensans/OpenSans-Bold.ttf') format('truetype'),
    url('../fonts/opensans/OpenSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/opensans/OpenSans-BoldItalic.ttf') format('truetype'),
    url('../fonts/opensans/OpenSans-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/opensans/OpenSans-ExtraBold.ttf') format('truetype'),
    url('../fonts/opensans/OpenSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/opensans/OpenSans-ExtraBoldItalic.ttf') format('truetype'),
    url('../fonts/opensans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/opensans/OpenSans-Italic.ttf') format('truetype'),
    url('../fonts/opensans/OpenSans-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/opensans/OpenSans-Light.ttf') format('truetype'),
    url('../fonts/opensans/OpenSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/opensans/OpenSans-Regular.ttf') format('truetype'),
    url('../fonts/opensans/OpenSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/opensans/OpenSans-Semibold.ttf') format('truetype'),
    url('../fonts/opensans/OpenSans-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/opensans/OpenSans-SemiboldItalic.ttf') format('truetype'),
    url('../fonts/opensans/OpenSans-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'OpenSansCondensed';
    src: url('../fonts/opensans/OpenSansCondensed-Bold.ttf') format('truetype'),
    url('../fonts/opensans/OpenSansCondensed-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansCondensed';
    src: url('../fonts/opensans/OpenSansCondensed-Light.ttf') format('truetype'),
    url('../fonts/opensans/OpenSansCondensed-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansCondensed';
    src: url('../fonts/opensans/OpenSansCondensed-LightItalic.ttf') format('truetype'),
    url('../fonts/opensans/OpenSansCondensed-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}








