/* autoprefixer grid: autoplace */

:root {
	--fontFamilyBase: 'OpenSans', Arial, sans-serif;
	--fontFamilySecond: 'OpenSansCondensed', Arial, sans-serif;
	--primaryColor: #005055;
    --secondaryColor: #FAEC4F;
    --tertiaryColor: #7ECADA;
    --redColor: #D11A28;
	--background: linear-gradient(180deg, #F5F5F5 0%, #005055 418.24%);
	--background2: #005055;
	--backgroundLight: #EEEEEE;
	--background4: #525252;
	--iconErrorColor: #F44336;
    --textColor1: #333333;
	--textColor2: #59616A;
	--textColor3: #BCBCBC;
	--textColor4: #ffffff;
	--textColor5: #848484;
	--textColor6: #006DA6;
    --buttonPrimary: #1FB4DA;
    --buttonPrimaryHover: rgba(0, 104, 159, 0.8);
    --buttonDark:  #32373c;
    --buttonDarkHover: rgba(50, 55, 60, 0.8);
	--primaryTextColor: #151515;
	--Platform-Gradient-V2: linear-gradient(180deg, #F5F5F5 0%, #005055 418.24%);
	--platform-primary-hover :linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(0, 133, 151, 0.80);
}

html, body {
	background: #EEEEEE;
	height: 100%;
	color: var(--textColor1);
    scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: var(--fontFamilyBase);
    -webkit-text-size-adjust: none;
}

body.no-scroll {
	overflow: hidden;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

* {
	font-family: var(--fontFamilyBase);
	-webkit-tap-highlight-color: transparent;
}

#root {
	min-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--fontFamilySecond);
	font-weight: 700;
}

h1 {
	font-size: 32px;
	line-height: 38px;
}

h2 {
    font-size: 28px;
	line-height: 20px;
}

h3 {
    font-size: 24px;
	line-height: 28px;
}

h4 {
    font-size: 20px;
    line-height: 1;
}

h5 {
	font-family: var(--fontFamilyBase);
	font-size: 18px;
	line-height: 20px;
}

h6 {
	font-family: var(--fontFamilyBase);
	font-size: 16px;
	line-height: 20px;
	font-weight: 500;
}

input,
button,
select {
	outline: none;
	font-family: var(--fontFamilyBase);
}

button {
	cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	transition: background-color 5000s ease-in-out 0s;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
textarea {
	font-size: 16px !important;
}

.overflow-popover > .MuiPaper-root {
	overflow: visible;
}
